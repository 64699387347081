'use strict';

import http from '@/utils/http';

export default {
  getOrderUserPageUrl: '/connections_treasure_box_admin_backend/sys-user/getOrderUserPage',
  getInvitationCodePage:'/connections_treasure_box_admin_backend/user/user-invitation/getInvitationCodePage',
  getInviterUserPage: '/connections_treasure_box_admin_backend/sys-user/getInviterUserPage',
  getOrderUserPage:'/connections_treasure_box_admin_backend/sys-user/getOrderUserPage',
  getData() {
    return http.get('/connections_treasure_box_admin_backend/sys-user/getOrderUserCount');
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/role/findAuthorityIdList/${roleId}`, { code });
  },
  add(code, data) {
    return http.post('/authority/applicationClient', data, { code });
  },
  update(code, data) {
    return http.put('/authority/applicationClient', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/applicationClient', { params, code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/role/saveAppClientRoleAuthority', data, {
      code,
    });
  },
  // 邀请码管理
  deleteInvit(code, params) {
    return http.delete('/connections_treasure_box_admin_backend/user/user-invitation', { params, code });
  },
  addInvit(code, data) {
    return http.post('/connections_treasure_box_admin_backend/user/user-invitation', data, { code });
  },
  updateInvit(code, data) {
    return http.put('/connections_treasure_box_admin_backend/user/user-invitation', data, { code });
  },

  getPackageInfoTypeDetailList(code, data) {
    return http.post('/connections_treasure_box_admin_backend/payment/package/getPackageInfoTypeDetailList', data, { code });
  },
  // 当前邀请码对应的赠送金币总数
  invitationCodeSendGold(code, userId) {
    return http.get(`/connections_treasure_box_admin_backend/user/user-invitation/invitationCodeSendGold/${userId}`, { code });
  },

  // 导出列表数据
  exportOrderUserPage(code, params) {
    return http.get('/connections_treasure_box_admin_backend/sys-user/exportOrderUserPage', {
      responseType: 'blob',
      code,
      params,
    });
  },

  // 邀请码管理编辑接口
  userInvitation(code, id) {
    return http.get(`/connections_treasure_box_admin_backend/user/user-invitation/${id}`, { code });
  },

  getOrderUserGoldNumDetail(code, id) {
    return http.get(`/connections_treasure_box_admin_backend/sys-user/getOrderUserGoldNumDetail/${id}`, { code });
  },

};
