<template>
  <div class="iss-main-index">
    <a-row :gutter="16"  class="rowClass">
      <a-col :span="8" class="left-pl">
        <div class="statistics-card" flex-attr="task">
          <div class="statistics-title">
            <div
              class="flex"
              style="justify-content: flex-start; align-items: baseline"
            >
              <div class="fs-32 mr-8">{{ orderTotalNum }}</div>
              <div class="fs-16">人</div>
            </div>
            <div class="fontStyle">
              邀请注册总数
              <a-tooltip
                title="邀请注册总数"
                placement="top"
                color="#fe700b"
                overlayClassName="tips"
              >
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
          </div>
          <div class="imgBox">
            <img src="@/assets/images/marketing/marketing-icon.jpg" alt="" />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="statistics-card" flex-attr="user">
          <div class="statistics-title">
            <div
              class="flex"
              style="justify-content: flex-start; align-items: baseline"
            >
              <div class="fs-32 mr-8">{{ activateTotalNum }}</div>
              <div class="fs-16">人</div>
            </div>
            <div class="fontStyle">
              邀请激活总数
              <a-tooltip title="邀请激活总数" placement="top" color="#fe700b">
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
          </div>
          <div class="imgBox">
            <img src="@/assets/images/marketing/marketing-icon.jpg" />
          </div>
        </div>
      </a-col>
      <a-col :span="8" class="left-pr">
        <div class="statistics-card" flex-attr="company">
          <div class="statistics-title">
            <div
              class="flex"
              style="justify-content: flex-start; align-items: baseline"
            >
              <div class="fs-32 mr-8">{{ subscribeTotalNum }}</div>
              <div class="fs-16">人</div>
            </div>
            <div class="fontStyle">
              邀请订阅总数
              <a-tooltip title="邀请订阅总数" placement="top" color="#fe700b">
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
          </div>
          <div class="imgBox">
            <img src="@/assets/images/marketing/marketing-icon.jpg" />
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="iss-main-grid" ref="mainRef">
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 2500, y: gridHeight }"
        >
          <template #avatar="{ record }">
            <div class="cursor-p primary-text " @click="goDetail(record)">
              <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
              <a-avatar ::size="30" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
          </template>
          <template #userName="{ record }">
            <div class="cursor-p primary-text" @click="goDetail(record)">
              {{ record.userName }}
            </div>
          </template>
          <template #orderUserNum="{ record }">
            <div class="cursor-p primary-text" @click="goDetail(record, '2')">
              {{ record.orderUserNum }}
            </div>
          </template>
          <template #subscribeStatus="{ record }">
          <div  v-if="record.subscribeStatus === 1">订阅</div>
          <div v-if="record.subscribeStatus === 0">到期</div>
        </template>
        <template #status="{ record }">
          <div v-if="record.status === 1">注册</div>
          <div v-if="record.status === 0">注销</div>
        </template>
        <template #beginnerGuide="{ record }">
          <div v-if="record.beginnerGuide === 1">已激活</div>
          <div v-if="record.beginnerGuide === 0">未激活</div>
        </template>
          <template #operation="{ record }">
            <operation :options="options" :record="record">
              <template #goDetail>
                <div class="primary-text cursor-p" @click="goDetail(record)">
                  详情
                </div>
              </template>
              <template #more>
                <div class="primary-text cursor-p">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                    <template #overlay>
                      <a-menu @click="clickOptions($event, record)">
                        <a-menu-item
                          v-for="item in itemOption"
                          :key="item.value"
                        >
                          <span class="padding-10">{{ item.label }}</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </operation>
          </template>
        </grid>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import {
  Col as ACol,
  Row as ARow,
  Tooltip as ATooltip,
  Dropdown,
  Menu,
  Modal, message, Avatar,
} from 'ant-design-vue';
import {QuestionCircleOutlined, UserOutlined} from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import { downloadExcel, setAvatar } from '@/utils';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import marketingApi from '@/api/marketing.js';
import userApi from '@/api/user.js';
import { useRouter } from 'vue-router';

export default {
  components: {
    ARow,
    ACol,
    ATooltip,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    QuestionCircleOutlined,
    SearchForm,
    Grid,
    Operation,
    AAvatar: Avatar,
    UserOutlined,
  },
  setup() {
    const state = reactive({
      search: {},
      orderTotalNum: 0,
      activateTotalNum: 0,
      subscribeTotalNum: 0,
    });
    const btnStatus = reactive({
      exportAllBtn: false,
    });
    const router = useRouter();

    const exportAllFunc = () => {
      btnStatus.exportAllBtn = true;
    };
    const goDetail = ({ id }, activeKey) => {
      const query = activeKey
        ? {
            activeKey,
          }
        : {};
      router.push({
        name: 'userDetail',
        params: {
          id,
        },
        query,
      });
    };
    const clickOptions = ({ key }, record) => {
      console.log(key, record);
      switch (key) {
        case 'order':
          goDetail(record, '3');
          break;
        case 'invitation':
          goDetail(record, '2');
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };
    marketingApi.getData().then(res => {
      if (res) {
        Object.assign(state,res)
      }
    })
    return {
      ...toRefs(state),
      setAvatar,
      gridHeight: document.body.clientHeight - 260,
      url: marketingApi.getOrderUserPageUrl,
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        {
          key: 'status',
          label: '用户状态',
          type: 'select',
          dataset: [
            { value: '1', label: '注册' },
            { value: '0', label: '注销' },
          ],
        },
        {
          key: 'subscribeStatus',
          label: '订阅状态',
          type: 'select',
          dataset: [
            { label: '订阅', value: 1 },
            { label: '到期', value: 0 },
          ],
        },
        {
          key: 'beginnerGuide',
          label: '激活状态',
          type: 'select',
          dataset: [
            { label: '已激活', value: 1 },
            { label: '未激活', value: 0 },
          ],
        },
        {
          key: 'channelSource',
          label: '来源渠道',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          url: `${userApi.treeList}?code=CHANNEL_SOURCE`,
        },
        {
          key: 'userName',
          label: '昵称或联系方式',
          type: 'inputName',
        }
      ],
      goDetail,
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'userName',
          title: '昵称',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'userName' },
        },
        {
          dataIndex: 'sex',
          title: '性别',
          width: 80,
          customRender: ({ record }) => {
            if (record.sex == 1) {
              return '男'
            }else if(record.sex == 0) {
              return '女'
            }else {
              return '未知'
            }
          }
        },
        {
          dataIndex: 'status',
          title: '用户状态',
          width: 100,
          customRender: ({ record }) => {
            if (record.status == 1) {
              return '注册'
            }else if(record.status == 0) {
              return '注销'
            }
          }
        },
        { dataIndex: 'id', title: '用户ID', width: 160, ellipsis: true },
        //   增加注册时间
        { dataIndex: 'createTime', title: '注册时间', width: 200, ellipsis: true },
        {
          dataIndex: 'mobile',
          title: '联系方式',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'orderUserNum',
          title: '邀请人数',
          width: 80,
          ellipsis: true,
          slots: { customRender: 'orderUserNum' },
        },
        { dataIndex: 'openId', title: 'open ID', width: 230, ellipsis: true },
        {
          dataIndex: 'memberExpireTime',
          title: '订阅到期时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'goldNum',
          title: '金币余额',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'lastLoginTime',
          title: '最后登录时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'formName',
          title: '来源渠道',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'status', title: '用户状态', width: 100, ellipsis: true,
          slots: { customRender: 'status'},
        },
        { dataIndex: 'subscribeStatus', title: '订阅状态', width: 100, ellipsis: true,
          slots: { customRender: 'subscribeStatus'},
        },
        { dataIndex: 'beginnerGuide', title: '激活状态', width: 100, ellipsis: true,
          slots: { customRender: 'beginnerGuide'},
        },
        {
          dataIndex: 'id',
          title: '操作',
          fixed: 'right',
          width: 130,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: () => {},
        },
        {
          type: 'more',
          slotName: 'more',
          title: '更多操作',
          permission: 'user:more',
          fnClick: () => {},
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            marketingApi
              .exportOrderUserPage('contact:exportAll', {exportCheckStatus: 1})
              .then(({ name, data }) => downloadExcel(name, data));
          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if(ids.length) {
              marketingApi
                  .exportOrderUserPage('contact:exportAll', { userIds: [...ids], exportCheckStatus: 0 })
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      exportAllFunc,
      clickOptions,
      handleFnSearch: value => {
        state.search = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-index {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px 40px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    font-size: 16px;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    img {
      height: 60px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rowClass {
  margin: 16px 8px 0px !important;
}

</style>
